<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { Header } from './layout/components'

export default {
  components: {
    Header
  },
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  margin: 0;
  height: 100vh;
  font-family: "SimHei";
}

@font-face {
  font-family: "kuzhankuaile";
  src: url("../src//assets/font/站酷快乐体.ttf");
}

@font-face {
  font-family: "youshebiaoti";
  src: url("../src//assets/font/优设标题黑.ttf");
}
</style>
