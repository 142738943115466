import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/',
    children: [
      {
        path: '/',
        component: () => import('@/views/index'),
        name: 'Index',
        meta: { title: '首页', icon: 'dashboard', affix: true }
      }
    ]
  },
  {
    path: '/about',
    component: Layout,
    children: [
      {
        path: '/about',
        component: () => import('@/views/aboutUs'),
        name: 'about',
        meta: { title: '关于我们', affix: true }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
