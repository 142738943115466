<template>
    <el-container>
        <el-header height="100">
            <Header></Header>
        </el-header>
        <el-main>
            <router-view />
        </el-main>
        <el-footer>
            <div class="footer-info">
                Copyright © 2018-2024 王禹效 All Rights Reserved. 备案号：<a href="https://beian.miit.gov.cn/"
                    target="_blank">粤ICP备2023117501号-1</a>
                <!-- <a href="https://beian.mps.gov.cn/#/query/webSearch?code=33021202002605" rel="noreferrer"
                    target="_blank" style="margin-left: 5px;"><img style="height: 15px;"
                        src="../assets/img/gongan.png" /><span>浙公网安备33021202002605号</span></a> -->
            </div>
        </el-footer>
    </el-container>
</template>
<script>
import { Header } from './components'

export default {
    components: {
        Header
    }
}
</script>
<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

body {
    margin: 0;
    height: 100vh;
    font-family: "SimHei";
}

.el-container {
    height: 100vh;
}

.el-main {
    padding: 0 !important;
}

.footer-info {
    font-size: 16px;
    color: #000;

    a {
        color: #000;
        text-decoration: none;
    }
}

.el-footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

@font-face {
    font-family: "kuzhankuaile";
    src: url("../assets/font/站酷快乐体.ttf");
}

@media screen and (max-width: 430px) {
    .footer-info {
        font-size: 12px;
        color: #000;

        a {
            color: #000;
            text-decoration: none;
        }
    }
}
</style>