<!--头部-->
<template>
    <div class="contain">
        <div class="logo-contain">
            <div class="icon">
                <!-- <el-image class="logo-img"
                    :src="require('@/assets/logo.png')"
                    fit="fill"></el-image> -->
            </div>
            <div class="logo-title">
                王禹效
            </div>
        </div>
        <div class="menu-contain">
            <el-menu :default-active="defaultActive" class="el-menu-header" mode="horizontal" text-color="#000"
                :router="true">
                <el-menu-item index="/">关于</el-menu-item>
                <!-- <el-menu-item index="/about">关于我们</el-menu-item> -->
            </el-menu>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Header',
    data() {
        return {
            defaultActive: this.$route.path
        }
    },
    created() {
        console.log(this.$route.path)
    }
}
</script>

<style scoped lang="scss">
@media screen and (min-width: 730px) {
    .contain {
        padding: 20px 0;
        width: 100%;
        height: 60px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;

        .logo-contain {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 0 0 60px;
            gap: 15px;

            .icon {
                .logo-img {
                    width: 55px; 
                    height: 55px;
                }
            }
            .logo-title {
                font-size: 36px;
                font-weight: 400;
                color: rgba(0, 0, 0, 1);
                font-style: italic;
                font-family: "youshebiaoti";
            }

        }

        .menu-contain {
            margin: 0 60px 0 0;

            .el-menu-header {
                display: flex;
                gap: 50px;
                border: 0 !important;
                font-weight: 800;
            }

            .el-menu-item {
                font-size: 18px;
                border-bottom: none !important;
            }

            .is-active {
                position: relative;
                text-align: center;
                border-bottom: none !important;
            }

            .is-active:after {
                content: "";
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                width: 40%;
                /* 控制边框的长度 */
                border-bottom: 4px solid #ee8970;
                /* 控制边框的样式 */
                height: 0;
                border-radius: 10px;
            }
        }
    }
}
@media screen and (max-width: 730px) {
    .contain {
        padding: 10px 0;
        width: 100%;
        height: 60px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;

        .logo-contain {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 0 0 20px;
            gap:10px;

            .icon {
                .logo-img {
                    width: 50px; 
                    height: 50px;
                }
            }

            .logo-title {
                font-size: 24px;
                font-weight: 400;
                color: rgba(0, 0, 0, 1);
                font-style: italic;
                font-family: "youshebiaoti";
            }

        }

        .menu-contain {
            margin: 0 0px 0 0;

            .el-menu-header {
                display: flex;
                gap: 10px;
                border: 0 !important;
                font-weight: 800;
            }

            .el-menu-item {
                font-size: 14px;
                border-bottom: none !important;
            }

            .is-active {
                position: relative;
                text-align: center;
                border-bottom: none !important;
            }

            .is-active:after {
                content: "";
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                width: 40%;
                /* 控制边框的长度 */
                border-bottom: 4px solid #ee8970;
                /* 控制边框的样式 */
                height: 0;
                border-radius: 10px;
            }
        }
    }
}
@media screen and (max-width: 430px) {
    .contain {
        padding: 10px 0;
        width: 100%;
        height: 60px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;

        .logo-contain {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 0 0 20px;
            gap:10px;

            .icon {
                .logo-img {
                    width: 50px; 
                    height: 50px;
                }
            }

            .logo-title {
                display: none;
            }

        }

        .menu-contain {
            margin: 0 0px 0 0;

            .el-menu-header {
                display: flex;
                gap: 10px;
                border: 0 !important;
                font-weight: 800;
            }

            .el-menu-item {
                font-size: 14px;
                border-bottom: none !important;
            }

            .is-active {
                position: relative;
                text-align: center;
                border-bottom: none !important;
            }

            .is-active:after {
                content: "";
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                width: 40%;
                /* 控制边框的长度 */
                border-bottom: 4px solid #ee8970;
                /* 控制边框的样式 */
                height: 0;
                border-radius: 10px;
            }
        }
    }
}
</style>